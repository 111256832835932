export default {
  vi: {
    caycanh: 'Cây Cảnh',
    senda: 'Sen Đá',
    cayphongthuy: 'Cây Phong Thuỷ',
    cayanqua: 'Cây Ăn Quả',
    dattrongcay: 'Đất Trồng Cây',
    caythuysinh: 'Cây Thuỷ Sinh',
    chauhoa: 'Hoa',
    hoalan: 'Hoa Lan',
    chaurau: 'Rau',
    adenium: 'Sứ Thái',
    arabicum: 'Sứ Kim Tự Tháp',
    adeniumSeeds: 'Hạt Giống Sứ',
  },
  'en-us': {
    caycanh: 'Ornamental Plants',
    senda: 'Succulent',
    cayphongthuy: 'FengShui Plants',
    cayanqua: 'Fruit Plants',
    dattrongcay: 'Plant Soils',
    caythuysinh: 'Aquatic Plants',
    chauhoa: 'Flowers',
    hoalan: 'Orchids',
    chaurau: 'Vegetables',
    adenium: 'Rosy Adenium',
    arabicum: 'Arabicum',
    adeniumSeeds: 'Adenium seeds',
  },
}
