/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import { withPrefix } from 'gatsby'
import React from 'react'
import { Helmet } from 'react-helmet'
import gI18n from '../../config/i18n'

const SEO = ({
  location,
  title,
  description,
  type = 'website',
  locale,
  meta = [],
  keywords = '',
  properties = {},
}: IProps) => {
  const siteMeta = gI18n[locale].siteMetadata
  const metaTitle = title || siteMeta.home
  const metaDescription = description || siteMeta.description
  const metaKeywords = keywords ? keywords : siteMeta.keywords.join(`, `)
  const metaImgUrl = gI18n[locale].siteMetadata.url + (properties.imgUrl ? properties.imgUrl : withPrefix('/logo@2x.png'))

  return (
    <Helmet
      htmlAttributes={{
        lang: locale,
        locale,
      }}
      title={metaTitle}
      titleTemplate={`%s | ${siteMeta.title}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: metaTitle,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: type,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: siteMeta.author,
        },
        {
          name: `twitter:title`,
          content: metaTitle,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        { name: 'msapplication-TileColor', content: '#da532c' },
        { name: 'theme-color', content: '#ffffff' },
        {
          name: `keywords`,
          content: metaKeywords,
        },
        {
          name: 'og:image',
          content: metaImgUrl,
        },
        {
          name: 'twitter:image',
          content: metaImgUrl,
        },
      ].concat(meta)}
    >
      <link rel="canonical" href={siteMeta.url + location.pathname + (location.search || '')} />
      <link rel="apple-touch-icon" sizes="180x180" href={withPrefix('/favicons/apple-touch-icon.png')} />
      <link rel="icon" type="image/png" sizes="32x32" href={withPrefix('/favicons/favicon-32x32.png')} />
      <link rel="icon" type="image/png" sizes="16x16" href={withPrefix('/favicons/favicon-16x16.png')} />
      {type === 'product' && (
        <script type="application/ld+json">{`
        {
          "@context" : "http://schema.org",
          "@type" : "Product",
          "name" : "${metaTitle}",
          "image" : "${metaImgUrl}",
          "description" : "${metaDescription}",
          "url" : "${location.href}",
          "brand" : {
            "@type" : "Brand",
            "name" : "SUNNY GARDEN",
            "logo" : "${withPrefix('/logo@2x.png')}"
          },
          "offers" : {
            "@type" : "Offer",
            "price" : ${properties.sellPrice}
          }
        }
        `}</script>
      )}
    </Helmet>
  )
}

interface IProps {
  description?: string
  lang?: string
  meta?: IMeta[]
  keywords?: string
  title?: string
  type?: string
  properties?: any
  locale: string
  location: Location
}

interface IMeta {
  name: string
  content: string
}

export default SEO
