import { TagOutlined } from '@ant-design/icons';
import { Tag } from 'antd';
import { Link } from 'gatsby';
import React from 'react';
import { localizedSlug } from '../../gatsby/NodeHelpers';
import i18n from './i18n';
import './index.scss';

type IType = 'blog' | 'shop'

interface Props {
  locale: string
  tags: string[]
  className: string
  type: IType
}

const tagColor = (tag: string) => {
  switch (tag) {
    case 'caycanh':
      return 'green'
    case 'chauhoa':
      return 'magenta'
    case 'hoalan':
      return 'green'
    case 'chaurau':
      return 'purple'
    case 'senda':
      return 'magenta'
    case 'cayphongthuy':
      return 'purple'
    case 'cayanqua':
      return 'geekblue'
    case 'dattrongcay':
      return 'volcano'
    case 'caythuysinh':
      return 'magenta'
    case 'adenium':
      return 'geekblue'
    case 'arabicum':
      return 'green'
    default:
      return 'purple'
  }
}

export const TagList = [
  'caycanh', 'chauhoa', 'hoalan', 'chaurau',
  'senda', 'cayphongthuy', 'cayanqua', 'dattrongcay', 'caythuysinh',
  'adenium', 'arabicum', 'adeniumSeeds'
]

export const TagListMap = (locale: string): { key: string; text: string }[] =>
  TagList.map(tag => ({ key: tag, text: i18n[locale][tag] }))

const Tags = ({ locale, tags, className, type }: Props) => (
  <>
    {tags.map((tag: string, index: number) => (
      <Link key={index} to={localizedSlug({ lang: locale, uid: `${type}/tags/${tag}` })} className={className}>
        {index === 0 && <TagOutlined />}
        <Tag color={tagColor(tag)}>{i18n[locale][tag]}</Tag>
      </Link>
    ))}
  </>
)

export default Tags
